import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import AddContractorModal from "components/pages/Settings/EditProject/components/AddContractorModal/AddContractorModal";
import { projectsApi } from "pages/Projects/model/api";
import { projectsActions } from "pages/Projects/model/projectsSlice";
import { ConstructionControlSuffix, IDetailedProject } from "pages/Projects/model/types";
import { getConstrControlAccountsForObject } from "pages/Settings/Object/model/thunks";
import { settingsProjectApi } from "pages/Settings/Project/model/settingsProjectApi";
import { getConstControlAccounts } from "pages/Settings/Project/model/thunks";

import { useTypedSelector } from "app/store/typedUseSelector";

import { useBuildingDetailDataById } from "hooks/useBuildingDetailDataById";

import { errorCatcher } from "utils/helpers/errorCatcher";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  objectId: string;
}

const ObjectSettingsControlModal: React.FC<IProps> = ({ isOpen, onClose, objectId }) => {
  const dispatch = useDispatch();
  const { buildingData: object, refetchBuilding } = useBuildingDetailDataById(objectId);
  const accounts = useTypedSelector(
    (state) => state.settingsReducer.settingsObjectReducer.constrControlAvailableAccounts
  )[objectId];

  const options = useMemo(() => {
    if (!accounts?.length) return [];
    const target = object?.construction_control;
    return accounts
      .filter((el) => !(target ?? []).some((targ) => targ.id === el.account_id))
      .map((el) => ({ ...el, id: el.account_id }));
  }, [accounts, object]);

  useEffect(() => {
    dispatch(getConstrControlAccountsForObject(objectId));
  }, [objectId]);

  const [isPending, setIsPending] = useState(false);
  const onSubmit = (ids: number[]) => {
    setIsPending(true);
    const reqs = ids.map((el) => settingsProjectApi.attachConstrControlToBuilding(el, objectId));
    Promise.all(reqs)
      .then(() => {
        refetchBuilding();
        onClose();
      })
      .catch(errorCatcher)
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <AddContractorModal
      isOpen={isOpen}
      onClose={onClose}
      contractors={options as any}
      contractorsCount={0}
      addContractors={onSubmit}
      handleChangeFilter={() => {}}
      handleLoadMore={() => {}}
      isPending={isPending}
      hideContractorInfo
    />
  );
};

export default ObjectSettingsControlModal;
