import classNames from "classnames";
import React, { FC, ReactNode } from "react";

import styles from "./ObjectExtendedRowItem.module.scss";

interface IProps {
  className?: string;
  name: ReactNode;
  children?: ReactNode;
  percents?: number;
  title?: string | number;
}

const ObjectExtendedRowItem: FC<IProps> = ({ className, name, children, percents, title }) => {
  return (
    <div
      className={classNames(styles.root, className, percents !== undefined && styles.withPercents)}
      title={String(title)}
    >
      <div className={styles.name}>{name}</div>
      {children}
      {percents !== undefined && (
        <div className={styles.percents}>
          <span>{percents}</span>%
        </div>
      )}
    </div>
  );
};

export default ObjectExtendedRowItem;
