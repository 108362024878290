import axios from "axios";
import React from "react";

import { FACT_INTERVALS, INTERVALS } from "../../../../../../../redux/modules/common/building/manufacturing/manufacturing";

import { errorCatcher } from "utils/helpers/errorCatcher";

interface IProps {
  intervalId: number;
  onIntervalReload: (id: number) => void;
  objectId: number | string;
  isPlan?: boolean;
}

export const useShareCompletedWork = ({ intervalId, onIntervalReload, objectId, isPlan }: IProps) => {
  const [comment, setComment] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSended, setIsSended] = React.useState(false);

  const commentHanlder = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  }, []);

  const activeModule = isPlan ? INTERVALS : FACT_INTERVALS;

  const submitHandler = React.useCallback(() => {
    const data = {
      // reason_msg: comment,
      count_accepted_work: 0
    };
    setIsLoading(true);
    setIsSended(true);
    axios.put(`/building/${objectId}/tickets/${intervalId}/`, data)
      .then(() => onIntervalReload(intervalId))
      .catch((err) => {
        errorCatcher(err);
        setIsSended(false);
      })
      .finally(() => setIsLoading(false));
  }, [comment, intervalId, objectId]);

  return {
    comment,
    commentHanlder,
    isLoading,
    submitHandler,
    isSended,
  };
};
