import { message } from "antd";
import { Dispatch } from "redux";

import { settingsProjectApi } from "./settingsProjectApi";
import { settingsProjectActions } from "./slice";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const getConstControlAccounts = () => (dispatch: Dispatch) => {
  settingsProjectApi.getConstControlAccounts().then(({ data }) => {
    dispatch(settingsProjectActions.setConstrContrAccounts(data.results));
  });
};

export const attachConstrControlToBuilding =
  (accountId: number, buildingId: string | number) => (dispatch: Dispatch) => {
    settingsProjectApi
      .attachConstrControlToBuilding(accountId, buildingId)
      .then(() => {
        message.success("Сохранено");
      })
      .catch(errorCatcher)
      .finally(() => {});
  };
