import classNames from "classnames";
import React, { FC, ReactNode } from "react";

import styles from "./ObjectExtendedSubRow.module.scss";

interface IProps {
  className?: string;
  children: ReactNode;
  indicator: ReactNode;
  indicatorClassName?: string;
}

const ObjectExtendedSubRow: FC<IProps> = ({ className, children, indicator, indicatorClassName }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <div className={classNames(styles.indicator, indicatorClassName)}>{indicator}</div>
      {children}
    </div>
  );
};

export default ObjectExtendedSubRow;
