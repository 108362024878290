import React, { FC, SetStateAction, useState } from "react";

import ButtonBase from "shared/ui/controls/ButtonBase";
import InputBase from "shared/ui/inputs/InputBase";
import { InputSearchRound } from "shared/ui/inputs/InputSearchRound/InputSearchRound";

import { IPermissionExtended } from "features/permissions/model/types";

import styles from "./InstructionsHead.module.scss";

interface IProps {
  search: string;
  setSearch: (value: string) => void;
  setActivePerm: (perm: string) => void;
  activePerm: string;
  fullActivePermission?: IPermissionExtended;
  toggleActivePerm?: () => void;
}

const InstructionsHead: FC<IProps> = ({
  search,
  setSearch,
  setActivePerm,
  activePerm,
  fullActivePermission,
  toggleActivePerm,
}) => {
  const sectionAlias = fullActivePermission?.parentChain?.[0]?.alias;

  const [localSearch, setLocalSearch] = useState(search);

  return (
    <header className={styles.header}>
      <h2>Инструкции Osla</h2>
      {!activePerm && (
        <InputBase
          value={localSearch}
          onChange={(e) => setLocalSearch(e.target.value)}
          placeholder="Поиск по инструкциям"
          onEnter={() => setSearch(localSearch)}
          icon={
            <div className={styles.searchBtn} onClick={() => setSearch(localSearch)}>
              Искать
              <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.83398 16.8646C11.5129 16.8646 13.1362 16.2512 14.4049 15.1375L15.1415 14.491L15.6776 13.6669C16.4341 12.504 16.834 11.1481 16.834 9.74575C16.834 5.82043 13.6938 2.62695 9.83398 2.62695C5.97417 2.62695 2.83398 5.82043 2.83398 9.74575C2.83398 13.6711 5.97417 16.8646 9.83398 16.8646ZM20.5674 20.4998C20.2801 20.4998 19.9915 20.3989 19.7574 20.193L15.7243 16.6406C14.1458 18.0263 12.0866 18.8646 9.83398 18.8646C4.86328 18.8646 0.833984 14.7822 0.833984 9.74575C0.833984 4.70935 4.86328 0.626953 9.83398 0.626953C14.8047 0.626953 18.834 4.70935 18.834 9.74575C18.834 11.5972 18.2897 13.3194 17.354 14.7576L21.3792 18.303C21.894 18.7566 21.9489 19.5468 21.5007 20.0688C21.2565 20.3538 20.9129 20.4998 20.5674 20.4998Z"
                  fill="white"
                />
              </svg>
            </div>
          }
          className={styles.search}
          classNameInput={styles.searchInput}
        />
      )}
      {sectionAlias && activePerm && (
        <ButtonBase className={styles.moduleBtn} secondary onClick={() => setActivePerm(sectionAlias!)}>
          Обзор модуля
        </ButtonBase>
      )}
      <ButtonBase className={styles.btn} primary onClick={toggleActivePerm}>
        {activePerm ? (
          "Все инструкции"
        ) : (
          <>
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.83149 3.395L6.18049 5.484L5.55149 3.395L3.46249 2.741L5.55149 2.112L6.18049 0L6.83149 2.112L8.92049 2.741L6.83149 3.395ZM10.4705 2.249L10.1555 3.303L9.79549 2.247L8.76249 1.91L9.79549 1.595L10.1545 0.562L10.4695 1.595L11.5025 1.91L10.4705 2.249ZM0.205486 13.724L13.0745 1.236C13.1203 1.17891 13.1785 1.13303 13.2447 1.10184C13.3109 1.07065 13.3833 1.05497 13.4565 1.056C13.559 1.04459 13.6629 1.05666 13.76 1.09131C13.8572 1.12597 13.9453 1.18228 14.0175 1.256C14.0855 1.301 14.1745 1.391 14.3095 1.503C14.5718 1.73537 14.8124 1.99109 15.0285 2.267C15.1084 2.37049 15.1554 2.49553 15.1635 2.626C15.177 2.72701 15.1678 2.82975 15.1367 2.92677C15.1055 3.0238 15.0532 3.11269 14.9835 3.187L2.11449 15.723C2.00517 15.8262 1.86426 15.8897 1.71449 15.903C1.61193 15.9144 1.50812 15.9023 1.41092 15.8677C1.31373 15.833 1.22569 15.7767 1.15349 15.703C1.08549 15.635 0.996486 15.568 0.861486 15.433C0.58857 15.2176 0.352198 14.9597 0.161486 14.669C0.0848677 14.5528 0.031861 14.4227 0.00548589 14.286C-0.00788504 14.1833 0.00326955 14.079 0.0380349 13.9815C0.0728002 13.8839 0.130186 13.795 0.205486 13.724ZM10.5365 4.65L11.4805 5.616L14.4675 2.716L13.5235 1.728L10.5365 4.65ZM4.27049 5.93L3.97849 6.963L3.66349 5.93L2.63149 5.595L3.66449 5.28L3.97949 4.224L4.27149 5.28L5.34949 5.595L4.27049 5.93ZM14.7365 5.144L15.0735 6.2L16.1065 6.515L15.0735 6.852L14.7365 7.885L14.3995 6.852L13.3665 6.515L14.3995 6.2L14.7365 5.144Z"
                fill="white"
              />
            </svg>
            <span>
              Показать для
              <br /> текущего экрана
            </span>
          </>
        )}
      </ButtonBase>
    </header>
  );
};

export default InstructionsHead;
