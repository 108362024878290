import { Moment } from "moment";
import React, { useEffect } from "react";
import { Field } from "react-final-form";
import { useDispatch } from "react-redux";

import { getConstControlAccounts } from "../../model/thunks";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import InputBase from "shared/ui/inputs/InputBase";

import { composeFieldValidators, maxLength, required } from "utils/formHelpers/validations";

import styles from "./ProjectSettingsDefaultTab.module.scss";

interface IProps {
  dateEnd: Moment;
  dateStart: Moment;
  setIsDirty: (val: boolean) => void;
  setDateEnd: (v: Moment) => void;
  setDateStart: (v: Moment) => void;
}

const ProjectSettingsDefaultTab: React.FC<IProps> = ({ dateEnd, dateStart, setDateEnd, setDateStart, setIsDirty }) => {
  return (
    <div className={styles.grid}>
      <Field
        name="address"
        label="*Адрес"
        component={InputBase}
        validate={composeFieldValidators(required(), maxLength(255))}
        classNameInput={styles.colorInput}
      />
      <Field
        name="permit_build"
        label="Разрешение на строительство"
        component={InputBase}
        validate={composeFieldValidators(maxLength(255))}
        classNameInput={styles.colorInput}
      />
      <Field
        name="responsible"
        label="*Ответственный"
        component={InputBase}
        validate={composeFieldValidators(required(), maxLength(255))}
        classNameInput={styles.colorInput}
      />
      <CalendarRange
        label="*Сроки строительства"
        defaultDateEnd={dateEnd}
        defaultDateStart={dateStart}
        setDefaultDateStart={(v) => {
          setIsDirty(true);
          setDateStart(v as any);
        }}
        setDefaultDateEnd={(v) => {
          setIsDirty(true);
          setDateEnd(v as any);
        }}
      />
      <Field
        name="customer"
        label="Заказчик"
        component={InputBase}
        validate={composeFieldValidators(maxLength(255))}
        classNameInput={styles.colorInput}
      />
      <Field
        name="developer"
        label="Застройщик"
        component={InputBase}
        validate={composeFieldValidators(maxLength(255))}
        classNameInput={styles.colorInput}
      />
      <Field
        name="general_contractor"
        label="Генеральный подрядчик"
        component={InputBase}
        validate={composeFieldValidators(maxLength(255))}
        classNameInput={styles.colorInput}
      />
      <Field
        name="technical_supervision"
        label="Технический надзор"
        component={InputBase}
        validate={composeFieldValidators(maxLength(255))}
        classNameInput={styles.colorInput}
      />
    </div>
  );
};

export default ProjectSettingsDefaultTab;
