import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import ButtonArrow from "shared/ui/controls/ButtonArrow/ButtonArrow";

import { IPermissionsTree } from "features/permissions/model/types";

import { useElementOnScreen } from "shared/lib/hooks/useElementOnScreen";

import styles from "./InstructionBottomSublist.module.scss";

interface IProps {
  group?: IPermissionsTree;
  setActivePerm?: (perm: string) => void;
}

const InstructionBottomSublist: FC<IProps> = ({ group, setActivePerm }) => {
  const count = useMemo(() => {
    return (
      group?.groups?.reduce((acc, curr) => {
        return acc + curr.permissions.length;
      }, 0) ?? 0
    );
  }, [group]);

  const ref = useRef<HTMLDivElement>(null);

  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(() => count > 3);

  const onClickRight = () => {
    const currentScroll = ref.current?.scrollLeft!;
    const oneItemWidth = ref.current?.querySelector<HTMLDivElement>(`.${styles.listItem}`)?.offsetWidth!;
    const newScroll = currentScroll + oneItemWidth;
    ref.current?.scroll({ left: newScroll });
  };

  const onClickLeft = () => {
    const currentScroll = ref.current?.scrollLeft!;
    const oneItemWidth = ref.current?.querySelector<HTMLDivElement>(`.${styles.listItem}`)?.offsetWidth!;
    const newScroll = currentScroll - oneItemWidth;
    ref.current?.scroll({ left: newScroll });
  };

  const onScroll = () => {
    if (ref.current?.scrollLeft === 0) {
      setShowLeft(false);
    } else {
      if (count > 3) {
        setShowLeft(true);
      }
    }
  };

  const [observerNode, isVisible] = useElementOnScreen<HTMLDivElement>();

  useEffect(() => {
    if (count > 3) {
      setShowRight(!isVisible);
    }
  }, [isVisible, count]);

  if (!count) return null;

  return (
    <div className={styles.list}>
      <span>{group?.name}</span>
      <div className={styles.container}>
        {showLeft && <ButtonArrow className={styles.left} onClick={onClickLeft} />}
        {showRight && <ButtonArrow className={styles.right} direction="right" onClick={onClickRight} />}
        <div className={styles.listWrapper} ref={ref} onScroll={onScroll}>
          {group?.groups?.map((g, groupIndex) =>
            g.permissions?.map((perm) => (
              <div
                className={styles.listItem}
                key={perm.alias}
                title={perm.title}
                onClick={() => {
                  setActivePerm?.(perm.alias);
                }}
              >
                <span>{group?.name}</span>
                {perm.title}
                <svg
                  className={styles.arrow}
                  width="11"
                  height="19"
                  viewBox="0 0 11 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 18.5181L9.759 9.75903L1 1.00006" stroke="black" />
                </svg>
              </div>
            ))
          )}
          {count > 3 && <div className={styles.rightObserver} ref={observerNode} />}
        </div>
      </div>
    </div>
  );
};

export default InstructionBottomSublist;
