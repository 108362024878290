import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userSelector } from "redux/modules/_TODO/auth";

import HelpModal from "../ui/HelpModal/HelpModal";
import HelpModalNavigation from "../ui/HelpModalNavigation/HelpModalNavigation";
import InstructionContent from "./ui/InstructionContent/InstructionContent";
import InstructionsHead from "./ui/InstructionsHead/InstructionsHead";
import InstructionsSection from "./ui/InstructionsSection/InstructionsSection";
import InstructionsSectionsList from "./ui/InstructionsSectionsList/InstructionsSectionsList";
import ErrorBoundary from "app/ErrorBoundary";
import {
  permissionsDescrSelector,
  permissionsInvalidateKeySelector,
  permissionsTreeSelector,
} from "features/permissions/model/selectors";
import { getPermissionsTree } from "features/permissions/model/thunks";
import ReactMarkdown from "markdown-to-jsx";
import InputBase from "shared/ui/inputs/InputBase";

import { useInstructionsRedirect } from "./hooks/useInstructionsRedirect";

import { permissionsUtils } from "features/permissions/model/utils";

import styles from "./Instructions.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  openedKey: number;
}

const Instructions: React.FC<IProps> = ({ isOpen, onClose, openedKey }) => {
  const dispatch = useDispatch();
  const tree = useSelector(permissionsTreeSelector);
  const descriptions = useSelector(permissionsDescrSelector);
  const user = useSelector(userSelector);
  const invalidatePermissionsKey = useSelector(permissionsInvalidateKeySelector);

  const [activePerm, setActivePerm] = useState("");

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!user?.id) return;
    dispatch(getPermissionsTree(user.id, true));
  }, [user?.id, invalidatePermissionsKey]);

  const autoAlias = useInstructionsRedirect({ isOpen, onSelect: setActivePerm, userId: user?.id });

  const fullActivePermission = descriptions?.[activePerm];

  const searchedTree = useMemo(() => {
    if (!tree) return null;
    return permissionsUtils.searchInTree(tree, search);
  }, [tree, search]);

  const parentChainDisplay = useMemo(() => {
    if (!fullActivePermission?.parentChain) return [];
    return []; //fullActivePermission.parentChain.filter((el) => !!el).filter((el) => el !== fullActivePermission?.title);
  }, [fullActivePermission?.parentChain, fullActivePermission?.title]);

  const onClickAllPerms = () => {
    setActivePerm((p) => (!!p ? "" : autoAlias));
  };

  const [permHistory, setPermHistory] = useState<string[]>([]);

  const onChangePerm = (perm: string) => {
    setPermHistory((prev) => [...prev, activePerm]);
    setActivePerm(perm);
  };

  const onBack = () => {
    setActivePerm(permHistory.at(-1)!);
    setPermHistory((prev) => prev.filter((_, i, arr) => i !== arr.length - 1));
  };

  return (
    <HelpModal openedKey={openedKey} isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <InstructionsHead
        setActivePerm={onChangePerm}
        search={search}
        setSearch={setSearch}
        toggleActivePerm={onClickAllPerms}
        activePerm={activePerm}
        fullActivePermission={fullActivePermission}
      />
      {!activePerm && (
        <div className={styles.root}>
          {searchedTree?.groups?.map((el) => (
            <InstructionsSectionsList key={el.name} title={el.name} items={el.groups} onClick={onChangePerm} />
          ))}
        </div>
      )}
      {activePerm && (
        <InstructionContent
          descriptions={descriptions!}
          fullActivePermission={fullActivePermission}
          setActivePerm={onChangePerm}
          onBack={onBack}
          showBack={!!permHistory.length}
        />
      )}
    </HelpModal>
  );
};

export default Instructions;

/* navHeader="Инструкции Osla"
      navContent={
        <>
          <div className={styles.searchWrapper}>
            <InputBase
              classNameInput={styles.searchInput}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className={styles.search}
              placeholder="Поиск инструкций"
            />
          </div>

          {searchedTree?.groups.map((el) => (
            <HelpModalNavigation
              activePerm={activePerm}
              key={el.name}
              group={el}
              onClick={setActivePerm}
              activePermPath={fullActivePermission?.parentChain}
              search={search}
              onOpen={(perm) => setActivePerm(perm)}
            />
          ))}
        </>
      }
      contentHeader={
        <>
          {parentChainDisplay.map((el, i) => (
            <Fragment key={el}>
              {i > 0 && <>&nbsp;/&nbsp;</>}
              {el}
            </Fragment>
          ))}
          {!!fullActivePermission?.title && !!parentChainDisplay.length && (
            <>
              &nbsp;<span>/</span>&nbsp;
            </>
          )}
          <span>{fullActivePermission?.title as string}</span>
        </>
      }
      content={
        <div className={styles.content}>
          {fullActivePermission?.videos?.map((el, i) => (
            <div key={i} className={styles.video}>
              <video src={el.url} controls muted={false} controlsList="nodownload" />
            </div>
          ))}
          <ErrorBoundary>
            <ReactMarkdown>{fullActivePermission?.description as string}</ReactMarkdown>
          </ErrorBoundary>
        </div>
      } */
