import React, { FC, Fragment, useMemo } from "react";

import HidebleText from "../HidebleText/HidebleText";
import InstructionsBottom from "../InstructionsBottom/InstructionsBottom";
import ErrorBoundary from "app/ErrorBoundary";
import ReactMarkdown from "markdown-to-jsx";
import ButtonBase from "shared/ui/controls/ButtonBase";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";

import { IPermissionExtended } from "features/permissions/model/types";

import { useInstructionsSelector } from "../../hooks/useInstructionsSelector";

import styles from "./InstructionContent.module.scss";
import globalStyles from "./InstructionGlobal.module.scss";

interface IProps {
  setActivePerm: (perm: string) => void;
  fullActivePermission?: IPermissionExtended;
  descriptions?: Record<string, IPermissionExtended>;
  onBack?: () => void;
  showBack?: boolean;
}

const InstructionContent: FC<IProps> = ({ setActivePerm, fullActivePermission, descriptions, onBack, showBack }) => {
  const sectionAlias = fullActivePermission?.parentChain?.[0]?.alias;
  const isTabLevel = fullActivePermission?.parentChain?.length === 3;

  const { sectionInstruction, siblings, tabNames } = useInstructionsSelector({ descriptions, fullActivePermission });

  const showPageInstruction = tabNames.includes(fullActivePermission?.alias!) && sectionInstruction && isTabLevel;

  const videos = useMemo(() => {
    const sectionPermVid = showPageInstruction
      ? sectionInstruction?.videos?.map((el) => ({
          src: el.url,
          name: sectionInstruction?.title,
        }))
      : [];
    const activePermVid = fullActivePermission?.videos?.map((el) => ({
      src: el.url,
      name: fullActivePermission?.title,
    }));
    return [...(sectionPermVid ?? []), ...(activePermVid ?? [])];
  }, [fullActivePermission, sectionInstruction, showPageInstruction]);

  return (
    <>
      <div className={styles.header}>
        {fullActivePermission?.parentChain?.map((el, i) => (
          <Fragment key={el.name}>
            {i > 0 && (
              <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.369019 0.458984L5.909 5.99998L0.369019 11.541" fill="#707070" />
              </svg>
            )}
            <div
              className={i > 0 ? styles.instructionName : styles.sectionName}
              onClick={() => setActivePerm(el.alias)}
            >
              {el.name}
            </div>
          </Fragment>
        ))}
      </div>
      {showBack && (
        <BackNavigationBar
          title={fullActivePermission?.parentChain?.[0]?.name!}
          onBack={onBack}
          className={styles.back}
        />
      )}
      <div className={styles.content}>
        <div className={globalStyles.content}>
          <ErrorBoundary>
            {showPageInstruction && <HidebleText description={sectionInstruction?.description as string} />}
            <HidebleText description={fullActivePermission?.description as string} />
          </ErrorBoundary>
        </div>

        {videos?.length > 0 && (
          <>
            <p className={styles.videoTitle}>Видео</p>
            <div className={styles.videoRow}>
              {videos?.map((el) => (
                <div key={el.src} className={styles.videoSlide}>
                  <div className={styles.videoWrapper}>{<video controls src={el.src} width="100%" />}</div>
                  <div className={styles.slideTitle}>{el.name}</div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <InstructionsBottom
        setActivePerm={setActivePerm}
        fullActivePermission={fullActivePermission}
        siblings={siblings}
      />
    </>
  );
};

export default InstructionContent;
