import { throttle } from "lodash";
import { useEffect, useRef } from "react";

import treeStyles from "../components/ChartTree/ChartTree.module.scss";
import { useRem } from "components/pages/Manufacturing/hooks/useRem";

import { getCorrespondingTreeElement } from "../utils/getElementsFromPointer";

export const useChartRunningLine = (contentRef: React.RefObject<HTMLElement | null> | undefined) => {
  const { REM } = useRem();
  const prevSelectedElement = useRef<Element | null>(null);

  useEffect(() => {
    const contentElement = contentRef?.current;
    if (!contentElement) return;
    const handleRunningLine = throttle((e: MouseEvent) => {
      const treeElement = getCorrespondingTreeElement(e, REM)?.parentElement;
      if (!treeElement || treeElement === prevSelectedElement.current) return;
      Array.from(
        treeElement.closest(`.${treeStyles.tree}`)?.querySelectorAll(`.${treeStyles.branchElement}`) || []
      ).forEach((el: Element) => el !== treeElement && el.classList.remove(treeStyles.activeBranch));
      treeElement.classList.add(treeStyles.activeBranch);
      prevSelectedElement.current = treeElement;
    }, 10);
    contentElement.addEventListener("mousemove", handleRunningLine);
    return () => {
      contentElement?.removeEventListener("mousemove", handleRunningLine);
    };
  }, [contentRef?.current, REM]);
};
