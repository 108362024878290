import { TASK_LISTS_TYPES } from "components/pages/Tasks/constants";

const breadcrumbs = [
  {
    name: "tasks",
    title: "Задачи",
    link: "/tasks",
    children: [
      {
        name: TASK_LISTS_TYPES.ALL,
        title: "Все задачи",
        link: `/${TASK_LISTS_TYPES.ALL}`,
      },
      {
        name: TASK_LISTS_TYPES.MY,
        title: "Мои задачи",
        link: `/${TASK_LISTS_TYPES.MY}`,
      },
      {
        name: TASK_LISTS_TYPES.ASSIGNED,
        title: "Поручил",
        link: `/${TASK_LISTS_TYPES.ASSIGNED}`,
      },
      {
        name: TASK_LISTS_TYPES.WATCHING,
        title: "Наблюдаю",
        link: `/${TASK_LISTS_TYPES.WATCHING}`,
      },
      {
        name: TASK_LISTS_TYPES.LISTS,
        title: "Списки задач",
        link: `/${TASK_LISTS_TYPES.LISTS}`,
      },
      {
        name: "profile",
        title: "Профиль",
        link: "/profile",
      },
      {
        name: "events",
        title: "События",
        link: "/events",
        children: [
          {
            name: "calendar",
            title: "Календарь",
            link: "/calendar",
          },
          {
            name: "payments",
            title: "Платежи",
            link: "/payments",
          },
          {
            name: "supplies",
            title: "Поставки",
            link: "/supplies",
          },
        ],
      },
      {
        name: "settings",
        title: "Настройки",
        link: "/settings",
      },
      {
        name: "files",
        title: "Хранилище",
        link: "/files",
      },
    ],
  },
  {
    name: "constructing",
    title: "Управление",
    link: "/constructing",
    children: [
      {
        name: "manufacturing",
        title: "График",
        link: "/manufacturing/",
      },
      {
        name: "analytics",
        title: "Аналитика",
        link: "/analytics",
      },
      {
        name: "events",
        title: "События",
        link: "/events",
        children: [
          {
            name: "payments",
            title: "Платежи",
            link: "/payments",
          },
          {
            name: "supplies",
            title: "Поставки",
            link: "/supplies",
          },
          {
            name: "calendar",
            title: "Календарь",
            link: "/calendar",
          },
        ],
      },
      {
        name: "finance",
        title: "Финансы",
        link: "/finance",
      },
      {
        name: "counterparties",
        title: "Контрагенты",
        link: "/counterparties",
      },
      {
        name: "documents",
        title: "Документы",
        link: "/documents",
      },
      {
        name: "projects",
        title: "Проекты",
        link: "/projects",
      },
      {
        name: "settings",
        title: "Настройки",
        link: "/settings",
      },
      {
        name: "profile",
        title: "Профиль",
        link: "/profile",
      },
      {
        name: "events",
        title: "События",
        link: "/events",
        children: [
          {
            name: "calendar",
            title: "Календарь",
            link: "/calendar",
          },
          {
            name: "payments",
            title: "Платежи",
            link: "/payments",
          },
          {
            name: "supplies",
            title: "Поставки",
            link: "/supplies",
          },
        ],
      },
      {
        name: "handler",
        title: "Смета",
        link: "/handler",
      },
      {
        name: "files",
        title: "Хранилище",
        link: "/files",
      },
    ],
  },
  {
    name: "purchases",
    title: "Закупки",
    link: "/purchases",
    children: [
      {
        name: "manufacturing",
        title: "График",
        link: "/manufacturing/",
      },
      {
        name: "requisitions",
        title: "Заявки",
        link: "/requisitions",
      },
      {
        name: "events",
        title: "События",
        link: "/events",
        children: [
          {
            name: "payments",
            title: "Платежи",
            link: "/payments",
          },
          {
            name: "supplies",
            title: "Поставки",
            link: "/supplies",
          },
          {
            name: "calendar",
            title: "Календарь",
            link: "/calendar",
          },
        ],
      },
      {
        name: "orders",
        title: "Заказы",
        link: "/orders",
      },
      {
        name: "counterparties",
        title: "Контрагенты",
        link: "/counterparties",
      },
      {
        name: "documents",
        title: "Документы",
        link: "/documents",
      },
      {
        name: "settings",
        title: "Настройки",
        link: "/settings",
      },
      {
        name: "profile",
        title: "Профиль",
        link: "/profile",
      },
      {
        name: "files",
        title: "Хранилище",
        link: "/files",
      },
      {
        name: "events",
        title: "События",
        link: "/events",
        children: [
          {
            name: "calendar",
            title: "Календарь",
            link: "/calendar",
          },
          {
            name: "payments",
            title: "Платежи",
            link: "/payments",
          },
          {
            name: "supplies",
            title: "Поставки",
            link: "/supplies",
          },
        ],
      },
    ],
  },
  {
    name: "objects",
    title: "Производство",
    link: "/objects",
    children: [
      {
        name: "manufacturing",
        title: "График",
        link: "/manufacturing2",
      },
      {
        name: "plan-fact",
        title: "План-факт",
        link: "/plan-fact",
      },
      {
        name: "events",
        title: "События",
        link: "/events",
      },
      {
        name: "settings",
        title: "Настройки",
        link: "/settings",
      },
      {
        name: "profile",
        title: "Профиль",
        link: "/profile",
      },
      {
        name: "estimate",
        title: "Проект",
        link: "/estimate",
      },
      {
        name: "journal",
        title: "Журнал учета",
        link: "/journal",
      },
      {
        name: "workers",
        title: "Сотрудники",
        link: "/workers",
      },
      {
        name: "materials",
        title: "Ресурсы",
        link: "/materials",
      },
      {
        name: "requisitions",
        title: "Заявки",
        link: "/requisitions",
      },
      {
        name: "stocks",
        title: "Склад",
        link: "/stocks",
      },
      {
        name: "beton",
        title: "Бетон",
        link: "/beton",
      },
      {
        name: "finance",
        title: "Финансы",
        link: "/finance",
      },
      {
        name: "documents",
        title: "Документы",
        link: "/documents",
      },
      {
        name: "contractors",
        title: "Подрядчики",
        link: "/contractors",
      },
      {
        name: "handler",
        title: "Обработка",
        link: "/handler",
      },
      {
        name: "files",
        title: "Хранилище",
        link: "/files",
      },
    ],
  },
  {
    name: "construction-control",
    title: "Стройконтроль",
    link: "/construction-control",
    children: [
      {
        name: "projects",
        title: "Проекты",
        link: `/projects`,
      },
      {
        name: "journal",
        title: "Журнал учета",
        link: `/journal`,
      },
      {
        name: "profile",
        title: "Профиль",
        link: "/profile",
      },
      {
        name: "events",
        title: "События",
        link: "/events",
        children: [
          {
            name: "calendar",
            title: "Календарь",
            link: "/calendar",
          },
          {
            name: "payments",
            title: "Платежи",
            link: "/payments",
          },
          {
            name: "supplies",
            title: "Поставки",
            link: "/supplies",
          },
        ],
      },
      {
        name: "settings",
        title: "Настройки",
        link: "/settings",
      },
      {
        name: "files",
        title: "Хранилище",
        link: "/files",
      },
    ],
  },
];

export const URL_MODULE_NAMES_MAP = {
  tasks: "Задачи",
  constructing: "Управление",
  purchases: "Закупки",
  objects: "Производство",
  "construction-control": "Стройконтроль",
};

export default breadcrumbs;
