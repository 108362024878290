import cn from "classnames";
import React, { FC } from "react";

import { confirmedIcon } from "../../assets/confirmedIcon";
import { processIcon } from "../../assets/processIcon";

import { ILearningItem } from "../../model/types";

import styles from "./LearningMenuItem.module.scss";

interface IProps {
  item: ILearningItem;
  isActive?: boolean;
  isLastAvailable?: boolean;
  onClick?: () => void;
}

const LearningMenuItem: FC<IProps> = ({ item, isActive, onClick, isLastAvailable }) => {
  return (
    <div className={cn(styles.item, isActive && styles.active)} onClick={!item.isDisabled ? onClick : undefined}>
      <div className={styles.info} title={item?.edu_data.title}>
        <p>{item?.edu_data.title}</p>
        <span>{item.menuTitle}</span>
      </div>
      <div className={styles.icon}>
        {item.is_confirmed && confirmedIcon}
        {isLastAvailable && isActive && processIcon}
        {isLastAvailable && !isActive && (
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="10" stroke="#4FB1EB" strokeWidth="2" />
          </svg>
        )}
        {item.isDisabled && (
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="10" stroke="#4FB1EB" strokeWidth="2" />
          </svg>
        )}
      </div>
    </div>
  );
};

export default LearningMenuItem;
