import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { projectsApi } from "pages/Projects/model/api";
import { projectsActions } from "pages/Projects/model/projectsSlice";
import { ConstructionControlSuffix, IDetailedProject } from "pages/Projects/model/types";
import { settingsProjectApi } from "pages/Settings/Project/model/settingsProjectApi";

import { useConfirmModal } from "entities/ConfirmationModal/ConfirmationContext";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import EmployeeElem from "shared/ui/dataDisplay/EmployeeElem/EmployeeElem";

import { useBuildingDetailDataById } from "hooks/useBuildingDetailDataById";

import { errorCatcher } from "utils/helpers/errorCatcher";

import styles from "./ProjectSettingsControlEmployee.module.scss";

interface IProps {
  title?: string;
  onClick: () => void;
  //
  objectId: string;
}

const ObjectSettingsControlEmployee: React.FC<IProps> = ({ title, onClick, objectId }) => {
  const { openConfirmModal, closeConfirmModal } = useConfirmModal();
  const dispatch = useDispatch();
  const { refetchBuilding, buildingData: object } = useBuildingDetailDataById(objectId);

  const [pendingIds, setPendingIds] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setPendingIds({});
  }, [object]);

  const items = object?.construction_control;

  return (
    <div>
      <div className={styles.title}>
        {title}
        <AddButton
          onClick={() => {
            onClick();
          }}
        />
      </div>
      <div className={styles.wrapper}>
        {items?.map((el) => (
          <EmployeeElem
            key={el.id}
            employeName={el.brand_name}
            content="Представитель застройщика по вопросам строительного контроля"
            canDelete
            isLoading={pendingIds[el.id]}
            onDelete={() => {
              openConfirmModal({
                title: "Удалить подрядчика?",
                variant: "secondary",
                isOpen: true,
                onClose: closeConfirmModal,
                action: () => {
                  closeConfirmModal();
                  setPendingIds((prev) => ({ ...prev, [el.id]: true }));
                  settingsProjectApi
                    .attachConstrControlToBuilding(null as any, objectId)
                    .then(() => {
                      refetchBuilding();
                    })
                    .catch((e) => {
                      setPendingIds((prev) => ({ ...prev, [el.id]: false }));
                      errorCatcher(e);
                    });
                },
              });
            }}
          />
        ))}
        {!items?.length && (
          <EmployeeElem
            employeName={"Не указан"}
            content="Представитель застройщика по вопросам строительного контроля"
            canAdd
            className={styles.empty}
            onClick={() => {
              onClick();
            }}
            onAdd={() => {
              onClick();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ObjectSettingsControlEmployee;
