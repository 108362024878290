import axios from "axios";
import cn from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { journalExecutionActions } from "redux/modules/common/building/journal/journalExecution/actions";
import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import addSvg from "./assets/add";
import aosrSvg from "./assets/aosr";
import passSvg from "./assets/passed";
import pendingSvg from "./assets/pending";
import { useConfirmModal } from "entities/ConfirmationModal/ConfirmationContext";
import { useCheckIsConstructingControl } from "features/constructingControl";
import AcceptCheckBtn from "shared/ui/controls/AcceptCheckBtn/AcceptCheckBtn";
import ContentWithCount from "shared/ui/dataDisplay/ContentWithCount/ContentWithCount";
import { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { ITicketInJournalDone } from "types/interfaces/Tickets";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import checkSvg from "../../../../../../../../../images/icons/blueCheckIcon";

import styles from "./JournalExecActions.module.scss";

interface IProps {
  ticket: IExpendituresInJournalFulfillment["items"][0];
  handleOpenAddRemarkModal: () => void;
  handleOpenRemarkModal: (onlyAccepted: boolean, count: number) => void;
  onAcceptTicket: () => void;
  onCreateAosr: () => void;
  isAosrDisabled: boolean;
  isShowFormationAosr: boolean;
  isShowCreateRemark: boolean;
  isShowTransferToAct: boolean;
  isHideRemarks?: boolean;
}

const JournalExecActions: React.FC<IProps> = ({
  handleOpenAddRemarkModal,
  handleOpenRemarkModal,
  ticket,
  onAcceptTicket,
  onCreateAosr,
  isAosrDisabled,
  isShowFormationAosr,
  isShowCreateRemark,
  isShowTransferToAct,
  isHideRemarks,
}) => {
  const ticketItem = ticket.expenditure ?? ticket.group ?? ticket.linked;

  const isConstructionControlEnabled = ticketItem?.cc_confirmed !== undefined;
  const isConstructionControlAccepted = !!ticketItem?.cc_confirmed;
  const isConstuctionControl = useCheckIsConstructingControl();

  const showTransferToAct = isShowTransferToAct && !isConstuctionControl;

  const [isPending, setIsPending] = useState(false);

  const { openConfirmModal, closeConfirmModal } = useConfirmModal();

  const onClickCC = () => {
    openConfirmModal({
      isOpen: true,
      onClose: closeConfirmModal,
      action: acceptCC,
      title: "Принять работу стройконтролем?",
      acceptButtonText: "Принять",
      className: styles.confirmModal,
    });
  };

  const dispatch = useDispatch();
  const { objectId } = useParams<any>();
  const acceptCC = () => {
    //TODO
    setIsPending(true);
    axios
      .post(`/construction_control/building/${objectId}/fulfillment_confirm/`, {
        year: 2024,
        month: 5,
        work: ticket.expenditure?.expenditure_id,
      })
      .then(() => {
        dispatch(journalExecutionActions.changeInvalidateKey());
        closeConfirmModal();
      })
      .catch(errorCatcher)
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <>
      {!isHideRemarks && (
        <TableReusableCell onClick={stopEventPropagation} className={cn(styles.remarks)}>
          {isShowCreateRemark && (
            <div className={styles.add} onClick={handleOpenAddRemarkModal}>
              <PopoverOverlay
                content={<div className={styles.popup}>Создать замечание</div>}
                openType="hover"
                popoverBorderColor="default"
                placement="bottom"
              >
                {addSvg}
              </PopoverOverlay>
            </div>
          )}
          <div
            className={styles.pendingRemarks}
            onClick={() => handleOpenRemarkModal(false, ticketItem?.count_remarks_in_work!)}
          >
            <PopoverOverlay
              content={
                <div className={styles.popup}>
                  {ticketItem?.count_remarks_in_work! > 0
                    ? `Замечаний к устранению: ${ticketItem?.count_remarks_in_work}`
                    : "Нет замечаний"}
                </div>
              }
              openType="hover"
              popoverBorderColor="default"
              placement="bottom"
            >
              <ContentWithCount
                count={ticketItem?.count_remarks_in_work!}
                countClassName={styles.pendingRemarksCount}
                isCountDisplayed={ticketItem?.count_remarks_in_work! > 0}
              >
                {pendingSvg}
              </ContentWithCount>
            </PopoverOverlay>
          </div>
          <div
            className={styles.pendingRemarks}
            onClick={() => handleOpenRemarkModal(true, ticketItem?.count_remarks_accepted!)}
          >
            <PopoverOverlay
              content={
                <div className={styles.popup}>
                  {ticketItem?.count_remarks_accepted! > 0
                    ? `Замечаний устранено: ${ticketItem?.count_remarks_accepted}`
                    : "Нет замечаний"}
                </div>
              }
              openType="hover"
              popoverBorderColor="default"
              placement="bottom"
            >
              <ContentWithCount
                count={ticketItem?.count_remarks_accepted!}
                isCountDisplayed={ticketItem?.count_remarks_accepted! > 0}
              >
                {passSvg}
              </ContentWithCount>
            </PopoverOverlay>
          </div>
          {(isShowFormationAosr || isShowTransferToAct) && <div className={styles.divider}></div>}
        </TableReusableCell>
      )}
      <TableReusableCell className={cn(styles.approve, { [styles.alone]: isHideRemarks })}>
        {isShowFormationAosr && (
          <div onClick={!isAosrDisabled ? onCreateAosr : undefined}>
            <PopoverOverlay
              content={<div className={styles.popup}>Сформировать Акт</div>}
              openType="hover"
              popoverBorderColor="default"
              placement="bottom"
              isDisabled={isAosrDisabled}
            >
              {aosrSvg}
            </PopoverOverlay>
          </div>
        )}
        {showTransferToAct && <AcceptCheckBtn onClick={onAcceptTicket} title="Подтвердить" />}
        {isConstructionControlEnabled && (
          <AcceptCheckBtn
            onClick={onClickCC}
            title={isConstructionControlAccepted ? "Принято стройконтролем" : "Принять стройконтролем"}
            isPending={isPending}
            disabled={!isConstuctionControl}
            isAccepted={isConstructionControlAccepted}
          />
        )}
      </TableReusableCell>
    </>
  );
};

export default React.memo(JournalExecActions);
