import React, { FC } from "react";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import CircleComponents from "shared/ui/dataDisplay/CircleComponents";

import { LOCALIZATION_CONFIG } from "constants/localization";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import { ReactComponent as CalendarIcon } from "images/icons/calendarBlack.svg";

import styles from "./ObjectsRowChartsPopup.module.scss";

interface IProps {
  title?: string;
  date?: string;
  items: { name: string; color: string; percents: number; value: string | number }[];
}

const ObjectsRowChartsPopup: FC<IProps> = ({ title, date, items }) => {
  return (
    <div className={styles.infoIcon} onClick={stopEventPropagation}>
      <PopoverOverlay
        placement="bottom"
        openType="hover"
        popoverBorderColor="primary"
        content={
          <div className={styles.popup}>
            {/* <div className={styles.head}>
              <div className={styles.title}>{title}</div>
              <div className={styles.date}>
                <CalendarIcon />
                {date}
              </div>
            </div> */}
            {items.map((el) => (
              <div key={el.name} className={styles.wrapper}>
                <CircleComponents
                  displayingValue={
                    <>
                      {transformDigitToFinancial(el.value, { withCurrencySign: false, withFloat: false })}
                      <span className={styles.currencySign}>{LOCALIZATION_CONFIG.currency}</span>
                    </>
                  }
                  description={el.name}
                  progress={el.percents}
                  color={el.color}
                  //subtitle={el.percents + "%"}
                  isProgressStringHidden
                  titleClassName={styles.chartValue}
                  classNameCircle={styles.classNameCircle}
                  descriptionClassName={styles.descriptionClassName}
                  subtitleClassName={styles.subtitleClassName}
                />
              </div>
            ))}
          </div>
        }
      >
        <InfoIcon />
      </PopoverOverlay>
    </div>
  );
};

export default ObjectsRowChartsPopup;

function InfoIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#4FB1EB" />
      <path
        d="M10.0302 6.29141C9.38616 6.29141 8.91016 5.82941 8.91016 5.24141C8.91016 4.65341 9.38616 4.19141 10.0302 4.19141C10.6742 4.19141 11.1502 4.62541 11.1502 5.19941C11.1502 5.81541 10.6882 6.29141 10.0302 6.29141ZM9.14816 14.9994V7.52341H10.8982V14.9994H9.14816Z"
        fill="white"
      />
    </svg>
  );
}
