import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import Select from "components/UI/atoms/Select";
import LabeledSwitch from "components/UI/atoms/_TODO/LabeledSwitch";
import EditProjectHeadline from "components/pages/Settings/EditProject/components/EditProjectHeadline/EditProjectHeadline";
import { useEditProject } from "components/pages/Settings/EditProject/hooks/useEditProject";
import { projectsV2DetailedSelector, projectsV2IsLoadingSelector } from "pages/Projects/model/selectors";
import { createProject, editProject, getDetailedProject } from "pages/Projects/model/thunks";
import { ICreateProject } from "pages/Projects/model/types";

import { DEFAULT_PROJECT_FIELDS } from "./constnts";
import ProjectSettingsControlTab from "./ui/ProjectSettingsControlTab/ProjectSettingsControlTab";
import ProjectSettingsDefaultTab from "./ui/ProjectSettingsDefaultTab/ProjectSettingsDefaultTab";
import { useTypedSelector } from "app/store/typedUseSelector";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import InputBase from "shared/ui/inputs/InputBase";

import { composeFieldValidators, maxLength, required } from "utils/formHelpers/validations";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./Project.module.scss";

const getTabs = (isNew: boolean) => [
  {
    id: `default`,
    text: "О проекте",
  },
  {
    id: `control`,
    text: "Стройконтроль",
    isHidden: isNew,
  },
];

const projectTypes = {
  business: "Бизнес",
  business_pro: "БизнесПро",
} as const;

const Project = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dateStart, setDateStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());
  const [isDirty, setIsDirty] = useState(false);
  const { projectId } = useParams<Record<string, string>>();
  const isNew = projectId === "new";
  const { entitiesList } = useEditProject("");

  const availableTypeProjects = useTypedSelector((state) => state.auth.user.available_type_projects);

  const onSubmit = (vals: ICreateProject) => {
    const newVals = Object.entries(vals).reduce<ICreateProject>((acc, [key, val]) => {
      if (val === null) {
        return {
          ...acc,
          [key]: "",
        };
      } else {
        return {
          ...acc,
          [key]: val,
        };
      }
    }, {} as ICreateProject);
    if (newVals.stock !== undefined) {
      delete newVals.stock;
    }
    if (isNew) {
      dispatch(
        createProject(
          {
            ...newVals,
            date_end: moment(dateEnd).format("YYYY-MM-DD"),
            date_start: moment(dateStart).format("YYYY-MM-DD"),
          },
          () => history.push("/constructing/projects/")
        )
      );
    } else {
      dispatch(
        editProject(projectId, {
          ...DEFAULT_PROJECT_FIELDS,
          ...newVals,
          date_end: moment(dateEnd).format("YYYY-MM-DD"),
          date_start: moment(dateStart).format("YYYY-MM-DD"),
        })
      );
    }
  };

  const invalidateKey = useTypedSelector((state) => state.projectsV2.invalidateKey);
  useEffect(() => {
    if (isNew) return;
    dispatch(getDetailedProject(projectId));
  }, [projectId, isNew, invalidateKey]);
  const pendingKey = generateStorageKey({ type: "pending" });
  const project = useSelector(projectsV2DetailedSelector)[projectId];
  const isLoading = useSelector(projectsV2IsLoadingSelector)[projectId];
  const isPending = useSelector(projectsV2IsLoadingSelector)[pendingKey];

  useEffect(() => {
    if (project?.date_start && project?.date_end) {
      setDateStart(moment(project?.date_start));
      setDateEnd(moment(project?.date_end));
    }
  }, [project]);

  const [activeTab, setActiveTab] = useState("default");

  const tabs = useMemo(() => {
    return getTabs(isNew);
  }, [isNew]);

  return (
    <TemplateBase>
      <Form
        onSubmit={onSubmit}
        initialValues={project}
        render={({ handleSubmit, dirty, form, hasValidationErrors }) => (
          <>
            <EditProjectHeadline
              isPending={isPending}
              isDirty={dirty}
              name={isLoading ? "" : isNew ? "Новый проект" : project?.name}
              submit={handleSubmit}
              isNew={isNew}
              resetForm={() => {
                form.restart();
              }}
              type="project"
            />
            <div className={styles.wrapper}>
              <div className={styles.row}>
                <Field
                  name="name"
                  validate={composeFieldValidators(required(), maxLength(255))}
                  label="*Наименование проекта"
                  component={InputBase}
                />
                <Field
                  name="entity"
                  validate={composeFieldValidators(required())}
                  render={({ input, meta }) => (
                    <Select
                      label="*Организация"
                      input={input}
                      meta={meta}
                      options={(entitiesList as any)?.map((el: any) => ({ name: el.name, id: el.id })) || []}
                    />
                  )}
                />
              </div>
              <TabBarNotLinks
                activeId={activeTab}
                onClick={setActiveTab}
                tabs={tabs}
                className={styles.mb32}
                isOnlyTabsView
              />
              {activeTab === "default" && (
                <ProjectSettingsDefaultTab
                  dateEnd={dateEnd}
                  dateStart={dateStart}
                  setDateEnd={setDateEnd}
                  setDateStart={setDateStart}
                  setIsDirty={setIsDirty}
                />
              )}
              {activeTab === "control" && <ProjectSettingsControlTab objectId="1488" project={project} />}
            </div>
          </>
        )}
      />
    </TemplateBase>
  );
};

export default Project;
