import { combineReducers } from "redux";

import auth, { USER_AUTO_LOGOUT, USER_LOGOUT } from "../../redux/modules/_TODO/auth";
import comments from "../../redux/modules/_TODO/comments/reducer";
import constructing from "../../redux/modules/_TODO/constructing";
import counterpartiesV2 from "../../redux/modules/_TODO/constructing/counterpartiesV2/counterpartiesCombinedReducer";
import notifications from "../../redux/modules/_TODO/notifications";
import approval from "../../redux/modules/_TODO/orderCard/approval/reducer";
import files from "../../redux/modules/_TODO/orderCard/files/reducer";
import orderMatrix from "../../redux/modules/_TODO/orderCard/orderMatrix";
import orders from "../../redux/modules/_TODO/orderCard/orders";
import settings from "../../redux/modules/_TODO/settings";
import { stockFilesReducer } from "../../redux/modules/_TODO/stockFiles/stockFilesReducer";
import userRedux from "../../redux/modules/_TODO/userRedux";
import analytics from "../../redux/modules/common/analytics/reducer";
import { betonReducer } from "../../redux/modules/common/building/beton/reducer";
import bills from "../../redux/modules/common/building/documents/bills";
import contracts from "../../redux/modules/common/building/documents/contracts";
import exports from "../../redux/modules/common/building/documents/exports";
import ksList from "../../redux/modules/common/building/documents/ksList/reducer";
import journalAcceptance from "../../redux/modules/common/building/journal/acceptance/reducer";
import acting from "../../redux/modules/common/building/journal/acting/reducer";
import aook from "../../redux/modules/common/building/journal/aook/reducer";
import aosr from "../../redux/modules/common/building/journal/aosr/reducer";
import journalExecution from "../../redux/modules/common/building/journal/journalExecution/reducer";
import log from "../../redux/modules/common/building/log";
import manufacturing from "../../redux/modules/common/building/manufacturing/manufacturing";
import importMaterials from "../../redux/modules/common/building/materials/importMaterials";
import materials from "../../redux/modules/common/building/materials/materials";
import specification from "../../redux/modules/common/building/materials/specification";
import nowObject from "../../redux/modules/common/building/object/nowObject";
import objects from "../../redux/modules/common/building/objects";
import posts from "../../redux/modules/common/building/posts/reducer";
import process from "../../redux/modules/common/building/process";
import project from "../../redux/modules/common/building/project/reducer";
import requisition from "../../redux/modules/common/building/requisition/reducer";
import requisitionsList from "../../redux/modules/common/building/requisitionsList/reducer";
import sections from "../../redux/modules/common/building/sections/sections";
import shedules from "../../redux/modules/common/building/shedules";
import stocks from "../../redux/modules/common/building/stocks";
import ticketsEstimate from "../../redux/modules/common/building/ticketsEstimate";
import virtualProviders from "../../redux/modules/common/building/virtualProviders";
import workers from "../../redux/modules/common/building/workers";
import documents from "../../redux/modules/common/documents/reducer";
import fileStorage from "../../redux/modules/common/filesStorage/filesStorage";
import ks6a from "../../redux/modules/common/ks6a/ks6a";
import ordersList from "../../redux/modules/common/orders/reducer";
import passports from "../../redux/modules/common/passportsAndCertificates/reducer";
import organization from "../../redux/modules/common/settings/organization";
import addresses from "../../redux/modules/common/settings/ourCompanyTabs/addresses";
import employees from "../../redux/modules/common/settings/ourCompanyTabs/employees";
import profile from "../../redux/modules/common/settings/ourCompanyTabs/profile";
import uiIndicators from "../../redux/modules/common/uiIndicators/reducer";
import events from "../../redux/modules/purchaser/events";
import payments from "../../redux/modules/purchaser/payments";
import kses from "redux/modules/common/building/documents/kses/reducer";
import serviceActs from "redux/modules/common/building/documents/serviceActs/reducer";
import journal from "redux/modules/common/building/journal/reducer";
import chart from "redux/modules/common/chart/reducer";

import tasks from "../../components/pages/Tasks/model/tasks";
import planFactVolume from "../../pages/PlanFact/PlanFactVolume/model/reducer";
import { buildingReducer } from "components/pages/Building/model/slice";
import billsV2 from "components/pages/Documents/Bills/model/slice";
import signatorsSlice from "components/pages/Settings/Signators/model/signatorsSlice";
import { tasksV2Reducer } from "components/pages/Tasks/model_v2/slice";
import { constructionControlProjectsReducer } from "pages/ConstructionControl/ConstructionControlProjects";
import { blueprintsQuery } from "pages/Estimate/Blueprints/model/blueprintsQuery";
import extraForecast from "pages/Finance/Forecast/widgets/AddExtraExpenseModal/model/extraForecastSlice";
import { financeReducer } from "pages/Finance/financeReducer";
import aggregations from "pages/Handler/ui/Aggregations/model/slice";
import autoCompleteOutOfEstimateSlice from "pages/Handler/ui/ProHandler/widgets/AutoCompleteOutOfEstimate/model/autoCompleteOutOfEstimate";
import { handlerIndexesReducer } from "pages/Handler/ui/ProHandler/widgets/HandlerIndexes";
import simpleHandler from "pages/Handler/ui/SimpleHandler/model/slice";
import { planFactReducer } from "pages/PlanFact";
import projectsV2 from "pages/Projects/model/projectsSlice";
import { settingsReducer } from "pages/Settings";

import { financeMeasureReducer as financeMeasure } from "../../features/financeMeasure";
import chatReducerV2 from "../../widgets/Chat/model/reducers";
import fileUploadState from "entities/FileUpload/model/slice";
import { reducer as createOutOfEstimateSection } from "features/CreateOutOfEstimateSection/model/slice";
import { buildingReducer as buildingFeature } from "features/building/model/slice";
import { objectsListReducer } from "features/objectsList";
import permissionsV2 from "features/permissions/model/permissionsSlice";
import addCheckpointState from "widgets/AddCheckpoint/model/addCheckpointSlice";
import viewCheckpointState from "widgets/ViewCheckpoint/model/viewCheckpointSlice";
import axesSlice from "widgets/AxesAndMarks/model/axesSlice";
import deletePlan from "widgets/DeletePlan/model/deletePlanSlice";
import learning from "widgets/Help/Learning/model/learningSlice";
import sendMultiplePlansState from "widgets/SendMultiplePlansForApprove/model/sendMultiplePlansSlice";

import { IbasicReduxAction } from "types/utilTypes/reduxTypes";

export const allReducers = {
  auth,
  documents,
  orderMatrix,
  settings,
  organization,
  employees,
  addresses,
  profile,
  orders,
  notifications,
  objects,
  bills,
  nowObject,
  requisition,
  virtualProviders,
  events,
  exports,
  stocks,
  workers,
  log,
  payments,
  tasks,
  contracts,
  shedules,
  process,
  posts,
  materials,
  ticketsEstimate,
  userRedux,
  sections,
  aggregations,
  constructing,
  specification,
  importMaterials,
  files,
  approval,
  manufacturing,
  ks6a,
  stockFilesReducer,
  comments,
  chatReducerV2,
  kses,
  serviceActs,
  fileStorage,
  journal,
  requisitionsList,
  ordersList,
  journalExecution,
  acting,
  aosr,
  ksList,
  passports,
  uiIndicators,
  analytics,
  aook,
  journalAcceptance,
  betonReducer,
  project,
  handlerIndexesReducer,
  sendMultiplePlansState,
  addCheckpointState,
  viewCheckpointState,
  fileUploadState,
  autoCompleteOutOfEstimateSlice,
  deletePlan,
  financeMeasure,
  projectsV2,
  permissionsV2,
  signatorsSlice,
  learning,
  extraForecast,
  counterpartiesV2,
  financeReducer,
  billsV2,
  objectsListReducer,
  settingsReducer,
  constructionControlProjectsReducer,
  buildingReducer,
  buildingFeature,
  chart,
  createOutOfEstimateSection,
  simpleHandler,
  axesSlice,
  [blueprintsQuery.reducerPath]: blueprintsQuery.reducer,
  tasksV2Reducer,
  planFactReducer,
};

export const appReducer = combineReducers(allReducers);

export const rootReducer = (state: ReturnType<typeof appReducer>, action: IbasicReduxAction) => {
  if (action.type === USER_LOGOUT) {
    state = {
      // @ts-ignore
      auth: {
        isAuthenticated: false,
      },
    };
  }

  if (action.type === USER_AUTO_LOGOUT) {
    // @ts-ignore
    state = {};
    localStorage.clear();
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;
