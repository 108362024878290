import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { getDetail, objectDetailSelector, setDetailNull } from "redux/modules/common/building/object/nowObject";

import { useTypedSelector } from "app/store/typedUseSelector";

export const useBuildingDetailDataById = (buildingId: string, isImmediatelyClearing?: boolean) => {
  const dispatch = useDispatch();
  const buildingData = useTypedSelector(objectDetailSelector);
  const [invalidateKey, setInvalidateKey] = useState(0);

  React.useEffect(() => {
    if (isNaN(+buildingId) || !buildingId) return;
    if (buildingData?.id === +buildingId) return;
    dispatch(getDetail(buildingId));
    return () => {
      if (isImmediatelyClearing) {
        dispatch(setDetailNull());
      }
    };
  }, [buildingId, buildingData?.id, invalidateKey]);

  const refetchBuilding = () => {
    dispatch(getDetail(buildingId));
  };

  return { buildingData, refetchBuilding };
};
