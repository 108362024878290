import cn from "classnames";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import ReactMarkdown from "markdown-to-jsx";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { ILearningItem } from "../../model/types";

import styles from "./LearningContent.module.scss";
import globalStyles from "./LearningContentGlobal.module.scss";

interface IProps {
  item?: ILearningItem;
  onOpenVideo?: () => void;
  onClose?: () => void;
}

const LearningContent: FC<IProps> = ({ item, onOpenVideo }) => {
  const [open, setOpen] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  const displayedHtml = useMemo(() => {
    const description = item?.edu_data.description ?? "";
    if (description.length < 450) {
      return description;
    }
    if (open) return description; //+ `<span class="${styles.more}">&nbsp;Скрыть</span>`;
    let res = description.substring(0, 450);
    if (res.length < description.length) {
      res = res + "...";
    }
    return res; //+ `<span class="${styles.more}">&nbsp;Читать еще</span>`;
  }, [item, open]);

  /* useEffect(() => {
    const target = ref.current?.querySelector<HTMLSpanElement>(`.${styles.more}`);
    target?.addEventListener("click", () => {
      setOpen((prev) => !prev);
    });
  }, [displayedHtml]); */

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <h2>{item?.edu_data.title}</h2> <div className={cn(styles.status)}>{getStatus(item)}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.descr} ref={ref}>
          <div className={styles.title}>Описание задания</div>
          <div className={cn(globalStyles.content, !open && "markdownClosed")}>
            <ReactMarkdown>{displayedHtml as string}</ReactMarkdown>
          </div>
        </div>
        {/* instructions */}
        <div className={styles.videoRoot}>
          <div className={styles.videoWrapper}>
            {[item?.edu_data.videos[0]]?.map((el) => (
              <video src={el?.url} className={styles.video} controls />
            ))}
          </div>
          <ButtonBase className={styles.separBtn} secondary onClick={onOpenVideo}>
            Показать отдельно
          </ButtonBase>
        </div>
      </div>
      {/* <div className={styles.controls}>
        <ButtonBase quaternary>
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.83149 3.395L6.18049 5.484L5.55149 3.395L3.46249 2.741L5.55149 2.112L6.18049 0L6.83149 2.112L8.92049 2.741L6.83149 3.395ZM10.4705 2.249L10.1555 3.303L9.79549 2.247L8.76249 1.91L9.79549 1.595L10.1545 0.562L10.4695 1.595L11.5025 1.91L10.4705 2.249ZM0.205486 13.724L13.0745 1.236C13.1203 1.17891 13.1785 1.13303 13.2447 1.10184C13.3109 1.07065 13.3833 1.05497 13.4565 1.056C13.559 1.04459 13.6629 1.05666 13.76 1.09131C13.8572 1.12597 13.9453 1.18228 14.0175 1.256C14.0855 1.301 14.1745 1.391 14.3095 1.503C14.5718 1.73537 14.8124 1.99109 15.0285 2.267C15.1084 2.37049 15.1554 2.49553 15.1635 2.626C15.177 2.72701 15.1678 2.82975 15.1367 2.92677C15.1055 3.0238 15.0532 3.11269 14.9835 3.187L2.11449 15.723C2.00517 15.8262 1.86426 15.8897 1.71449 15.903C1.61193 15.9144 1.50812 15.9023 1.41092 15.8677C1.31373 15.833 1.22569 15.7767 1.15349 15.703C1.08549 15.635 0.996486 15.568 0.861486 15.433C0.58857 15.2176 0.352198 14.9597 0.161486 14.669C0.0848677 14.5528 0.031861 14.4227 0.00548589 14.286C-0.00788504 14.1833 0.00326955 14.079 0.0380349 13.9815C0.0728002 13.8839 0.130186 13.795 0.205486 13.724ZM10.5365 4.65L11.4805 5.616L14.4675 2.716L13.5235 1.728L10.5365 4.65ZM4.27049 5.93L3.97849 6.963L3.66349 5.93L2.63149 5.595L3.66449 5.28L3.97949 4.224L4.27149 5.28L5.34949 5.595L4.27049 5.93ZM14.7365 5.144L15.0735 6.2L16.1065 6.515L15.0735 6.852L14.7365 7.885L14.3995 6.852L13.3665 6.515L14.3995 6.2L14.7365 5.144Z"
              fill="black"
            />
          </svg>
          Открыть связанные инструкции
        </ButtonBase>
        <ButtonBase quaternary>Пропустить задание</ButtonBase>
        <ButtonBase primary>Перезапустить задание</ButtonBase>
      </div> */}
    </div>
  );
};

export default LearningContent;

function getStatus(item?: ILearningItem) {
  if (!item) return "";
  if (item?.is_confirmed) return "Выполнено";
  if (item?.isDisabled) return "К выполнению";
  return "Выполняется";
}
