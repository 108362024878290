import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { chartActionsSelector } from "redux/modules/common/chart/selectors";
import { ChartModalType, IChartFactWork, IChartFactWorkCompletionType } from "redux/modules/common/chart/types";

import IntervalGroupModal from "components/UI/_TODO/Expenditure/components/IntervalGroupModal/IntervalGroupModal";
import ManufacturingModal from "components/pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModal";
import { INTERVAL_TYPES } from "components/pages/Manufacturing/constants";

import type { IChartIntervalProps } from "../ChartInterval.typings";
import { CHART_Z_INDEX } from "../const";

import { AlertIcon } from "../../../../../../images/icons/AlertIcon";

import intervalStyles from "../ChartInterval.module.scss";
import styles from "./withWork.module.scss";

export interface IChartWorkIntervalProps
  extends Omit<
    IChartIntervalProps,
    "start" | "end" | "id" | "forwardRef" | "openModal" | "closeModal" | "intervalData" | "popupType"
  > {
  work: IChartFactWork;
  modalType: ChartModalType;
}

function withWork(Base: React.FC<IChartIntervalProps>) {
  return ({ work, modalType, ...props }: IChartWorkIntervalProps) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const chartActions = useSelector(chartActionsSelector);

    const closeModal = useCallback(() => {
      setIsOpenModal(false);
    }, []);

    const openModal = useCallback(() => {
      setIsOpenModal(true);
    }, []);

    const isGroup = work.type === "group";

    const percentages: Record<IChartFactWorkCompletionType, number> = useMemo(() => {
      const completionMax = Math.max(
        Number(work.completed),
        Number(work.accepted),
        // TODO: детализировать статус "Подтверждено" в попапе
        // Number(work.confirmed),
        Number(work.todo),
        Number(work.to_pay)
      );
      return {
        completed: Number(work.completed) > 0 && completionMax > 0 ? Number(work.completed) / completionMax : 0,
        accepted: Number(work.accepted) > 0 && completionMax > 0 ? Number(work.accepted) / completionMax : 0,
        // TODO: детализировать статус "Подтверждено" в попапе
        // confirmed: Number(work.confirmed) > 0 && completionMax > 0 ? Number(work.confirmed) / completionMax : 0,
        todo: Number(work.todo) > 0 && completionMax > 0 ? Number(work.todo) / completionMax : 0,
        to_pay: Number(work.to_pay) > 0 && completionMax > 0 ? Number(work.to_pay) / completionMax : 0,
      };
    }, [work]);

    const remarksCount = (work.ticket_remarks_in_work?.length || 0) + (work.remarks_in_work?.length || 0);
    const isRemarkChipVisible = chartActions.remarks_visible;

    return (
      <Base
        {...props}
        id={isGroup ? `group_${work.group?.id}` : `work_${work.expenditure?.id}`}
        start={work.start}
        end={work.end}
        openModal={openModal}
        // @ts-ignore
        closeModal={closeModal}
        intervalData={work}
        popupType={INTERVAL_TYPES.work}
        zIndex={CHART_Z_INDEX.WORK}
        remarksCount={remarksCount}
        sharedStatus={work.is_shared ? work.status : undefined}
        innerBeforeContent={
          <>
            {props.innerBeforeContent}
            {isRemarkChipVisible && !!remarksCount && (
              <div className={styles.remarksChip}>
                <AlertIcon fill={"white"} width={16} /> {remarksCount}
              </div>
            )}
          </>
        }
        children={
          <>
            {props.children}
            <div className={cn(styles.work, intervalStyles.ticketShape)}>
              {percentages.completed > 0 && (
                <div className={styles.doneBg} style={{ width: `${Math.min(percentages.completed * 100, 100)}%` }} />
              )}
              {percentages.accepted > 0 && (
                <div
                  className={styles.receivedBg}
                  style={{
                    width: `${Math.min(percentages.accepted * 100, 100)}%`,
                  }}
                />
              )}
              {percentages.todo > 0 && (
                <div className={styles.todoBg} style={{ width: `${Math.min(percentages.todo * 100, 100)}%` }} />
              )}
              {/*
                  // TODO: детализировать статус "Подтверждено" в попапе
                  {percentages.confirmed > 0 && (
                <div
                  className={styles.confirmedBg}
                  style={{
                    width: `${Math.min(percentages.confirmed * 100, 100)}%`,
                  }}
                />
              )} */}
              {percentages.to_pay > 0 && (
                <div className={styles.topayBg} style={{ width: `${Math.min(percentages.to_pay * 100, 100)}%` }} />
              )}
            </div>
          </>
        }
        afterContent={
          <>
            {props.afterContent}
            {!isGroup && isOpenModal && (
              <ManufacturingModal
                isOpen
                onClose={closeModal}
                type="work"
                date_start={work.start}
                date_end={work.end}
                objectId={props.projectId.toString()}
                // @ts-ignore
                intervaldata={work.expenditure}
                expenditureId={
                  work.expenditure?.exp_id || work.expenditure?.cs_id || (work.expenditure?.ps_id as number)
                }
                sectionId={work.expenditure?.cs_id || (work.expenditure?.ps_id as number)}
                modalType={modalType}
                updateWorkStatus={() => {}}
              />
            )}
            {isGroup && isOpenModal && (
              <IntervalGroupModal
                isOpen
                onClose={closeModal}
                activeModule="facts"
                date_start={work.start}
                date_end={work.end}
                expenditureId={work.group?.group_id as number}
                expenditure={{}}
                // sectionName={work.section_name}
                objectId={props.projectId.toString()}
                sectionId={work.group?.cs_id as number}
                sectionName=""
              />
            )}
          </>
        }
      />
    );
  };
}

export default withWork;
