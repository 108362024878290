import cn from "classnames";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  journalAcceptanceSectionsIsLoadingSelector,
  journalAcceptanceSectionsSelector,
  journalAcceptanceSharedContractorsSelector,
} from "redux/modules/common/building/journal/acceptance/selectors";
import { getAcceptanceSections } from "redux/modules/common/building/journal/acceptance/thunks";
import { journalTicketsInvalidateKeySelector } from "redux/modules/common/building/journal/selectors";
import { generateJornalTicketSectionKey } from "redux/modules/common/building/journal/utils";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import JournalContractors from "../JournalContractors/JournalContractors";
import JournalSection from "./JournalSection/JournalSection";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { DEFAULT_ENTITY_ID } from "../../constants";

import { useFoldersNavigation } from "hooks/navigation/useFoldersNavigation";
import { useBuildingDetailDataById } from "hooks/useBuildingDetailDataById";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./JournalSections.module.scss";

interface IProps {
  month: number;
  year: number;
  onSectionClick: (sectionId: number) => void;
  onContractorsClick: (contactorId: number, name: string) => void;
  onClickAddPlan: () => void;
  currentContractorId: number;
  onBackFromSection: () => void;
  sectionId: number;
  activeContractorName: string;
}

const JournalSections: React.FC<IProps> = ({
  month,
  year,
  onSectionClick,
  onClickAddPlan,
  onContractorsClick,
  currentContractorId,
  onBackFromSection,
  sectionId,
  activeContractorName,
}) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const key = generateJornalTicketSectionKey(objectId, year, month);
  const sections = useSelector(journalAcceptanceSectionsSelector)[key];
  const isSectionsLoading = useSelector(journalAcceptanceSectionsIsLoadingSelector)[key];
  const sharedContractors = useSelector(journalAcceptanceSharedContractorsSelector)[key];
  const invalidateKey = useSelector(journalTicketsInvalidateKeySelector);

  const { buildingData: buildingData } = useBuildingDetailDataById(objectId);

  React.useLayoutEffect(() => {
    dispatch(getAcceptanceSections(objectId, year, month));
  }, [objectId, year, month, invalidateKey]);

  const showContractorsLevel = !!sharedContractors?.length && currentContractorId !== DEFAULT_ENTITY_ID;
  const isOwnSectionsInSharedObject = !!sharedContractors?.length && currentContractorId === DEFAULT_ENTITY_ID;

  return (
    <div className={cn({ [styles.container]: !sharedContractors?.length })}>
      {isOwnSectionsInSharedObject && (
        <BackNavigationBar title={buildingData?.entity_name!} onBack={onBackFromSection} className={styles.navbar} />
      )}
      {isSectionsLoading && <Spinner />}
      {showContractorsLevel && (
        <JournalContractors
          month={month}
          year={year}
          onSectionClick={onSectionClick}
          contractors={sharedContractors}
          currentContractorId={currentContractorId}
          onBackFromSection={onBackFromSection}
          currentSectionId={sectionId}
          onContractorClick={onContractorsClick}
          activeContractorName={activeContractorName}
        />
      )}
      {!showContractorsLevel &&
        sections?.map((el) => (
          <JournalSection onSectionClick={onSectionClick} key={el.id} section={el} month={month} year={year} />
        ))}
      {!showContractorsLevel && !isSectionsLoading && !sections?.length && (
        <EmptyPlaceholder text="Нет сведений" img={jurnalIcon}>
          <ButtonBase primary onClick={onClickAddPlan}>
            Добавить запись
          </ButtonBase>
        </EmptyPlaceholder>
      )}
    </div>
  );
};

export default React.memo(JournalSections);
