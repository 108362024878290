import axios from "axios";
import { Dispatch } from "redux";

import { objectsListActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { IObjectInList } from "./types";
import { IListResponseData } from "types/ListResponse";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const loadObjects = (paginationParams: Record<any, any>, filterParams: Record<any, any>) => {
  const config = { params: { ...paginationParams, ...filterParams, analytics: 1 } };
  return (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey(filterParams as any);
    if (!getState()?.objectsListReducer.objects[key]) {
      dispatch(objectsListActions.setIsLoading({ key, status: true }));
    }
    axios
      .get<IListResponseData<IObjectInList>>(`/constructing/projects/`, config)
      .then(({ data }) => {
        dispatch(objectsListActions.setObjects({ data, key }));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(objectsListActions.setIsLoading({ key, status: false }));
      });
  };
};

export const loadMoreObjects = (paginationParams: Record<any, any>, filterParams: Record<any, any>) => {
  const config = { params: { ...paginationParams, ...filterParams } };
  return (dispatch: Dispatch) => {
    const key = generateStorageKey(filterParams as any);
    axios
      .get<IListResponseData<IObjectInList>>(`/constructing/projects/`, config)
      .then(({ data }) => {
        dispatch(objectsListActions.setMoreObjects({ data, key }));
      })
      .catch(errorCatcher);
  };
};

export const changeObjectStatus =
  (id: number | string, status: string) => (dispatch: Dispatch, getState: () => RootState) => {
    return axios
      .post(`/building/${id}/estimate/items/entities/change_status/`, {
        status,
      })
      .then(() => {
        dispatch(objectsListActions.invalidateKey());
      })
      .catch(errorCatcher);
  };
